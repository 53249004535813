import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTiles from "../components/globalTiles"
import ImageTween from "../components/imageTween"

const Covid = () => {
  const lang = "en"

  return (
    <Layout lang={lang} translation="/pl/covid-19/">
      <SEO title="Covid 19" />

      <section className="covid-top global__padd--bigger">
        <h1>Getting ready for the new normal - The road back to the office</h1>
        <div className="row align-items-center">
          <div className="col-md-8">
            <div className="covid-top__inner">
              <p>
                The pandemic has not only changed the patterns of personal
                interactions - with increased sanitary measures, social
                distancing and the rise of digital communication - but also
                fundamentally altered the way we work. With the progressive
                loosening of protective government regulations, institutions and
                companies are preparing to their new normal.
                <br />
                <br />
                We have taken precautionary measures designing the UNITY CENTRE
                complex to stop the spread of COVID-19. This is how we improve
                our buildings architecture to serve the safety issues:
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <ImageTween
              src={require("../assets/images/covid-content-image-2.jpg")}
              srcSet={require("../assets/images/covid-content-image-2@2x.jpg")}
              alt=""
            />
          </div>
        </div>
        <span className="global__line"></span>
      </section>

      <section className="covid-content global__padd--bigger">
        <div className="row">
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../assets/images/covid-content-ico-1.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Keeping social distance</h4>
                <p className="covid-touppercase">
                  For the safety of all our Tenants, we have prepared markings
                  indicating the maintenance of a safe distance while moving
                  around the Complex to help staying min. 2 meters away from
                  each other.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../assets/images/covid-content-ico-2.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>No gathering in larger groups</h4>
                <p className="covid-touppercase">
                  Public areas have been designed to prevent gathering in larger
                  groups. Appropriate arrangement of furniture and signage helps
                  to disperse groups of people staying in the Building lobbies
                  or in the UNITY Square.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../assets/images/covid-content-ico-3.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Instructions and reminders</h4>
                <p className="covid-touppercase">
                  In order to support proper hand hygiene in our complex,
                  directions on how to properly wash your hands for min. 30 sec
                  are placed in all toilets. Humorous posters will surely make
                  washing your hands more enjoyable.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../assets/images/covid-content-ico-4.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Use of lifts max. 3 people</h4>
                <p className="covid-touppercase">
                  While maintaining a safe distance, restrictions on the number
                  of people using the elevators have been implemented.
                  Currently, up to 3 people can enter each of them. This allows
                  us to keep distance and increase the sense of security even in
                  a closed and not very large area.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../assets/images/covid-content-ico-5.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Regular disinfection</h4>
                <p className="covid-touppercase">
                  All most frequently used places are disinfected on an ongoing
                  basis by cleaning service. All used products are certified -
                  destroy viruses, but are safe for people. In all high-traffic
                  areas, we also use disposable cleaning cloths for
                  disinfection.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../assets/images/covid-content-ico-6.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Hand sanitizers</h4>
                <p className="covid-touppercase">
                  Available in common areas for all users. Hand hygiene is next
                  to face covering the most important element of fighting
                  viruses. Sanitising stations are available in common areas to
                  make sure that every user of the Complex can effectively
                  disinfect their hands and feel safe during their visit in
                  UNITY CENTRE.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../assets/images/covid-content-ico-7.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Cover face in common areas</h4>
                <p className="covid-touppercase">
                  In order to take maximum care of the health of our Tenants, it
                  is obligatory to wear masks in common areas of the Complex
                  i.e. Main Lobbies, lifts, parking garage. Correct wearing of a
                  mask or other cover helps to avoid contracting not only
                  COVID-19, but all viruses that are transmitted by airborne
                  droplets. Masks for the forgetful will be available at the
                  main reception desk in all buildings.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../assets/images/covid-content-ico-8.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Couriers and suppliers</h4>
                <p className="covid-touppercase">
                  Taking care of the comfort and safety of our tenants, we have
                  decided to reorganize the issues related to the delivery of
                  letters, parcels and food in our Complex. All couriers and
                  suppliers are admitted only to the reception area of ​​a given
                  building and there they are asked to leave the package or wait
                  for personal pickup. This solution helps to avoid transmitting
                  viruses into the office building, which can also be present on
                  clothes or objects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="covid-grid global__padd">
        <div className="row no-gutters align-items-center">
          <div className="col-md-8">
            <div className="covid-grid__inner">
              <h2>Action plan for a safe office</h2>
              <p>
                We’re here to help. Do you need support preparing for a
                successful return to your physical offices? Our teams are here
                to help you pave the way by developing safe workplaces and
                facilitating the ‘next normal’ with tailor-made solutions.
              </p>
              <Link
                to="/contact/"
                className="btn-custom-back btn-custom-back--orange"
              >
                Connect with Us
              </Link>
            </div>
          </div>
          <div className="col-md-4">
            <ImageTween
              src={require("../assets/images/covid-content-image-1.jpg")}
              srcSet={require("../assets/images/covid-content-image-1@2x.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>

      <GlobalTiles lang={lang} />
    </Layout>
  )
}

export default Covid
